import { HelpOutline as HelpOutlineIcon, Done as DoneIcon } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box, Button, Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton, Tooltip, Typography
} from "@mui/material";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { getAuthHeader } from "utils";
import { getFileNameFromUrl } from "utils/url";
// @ts-ignore
import videojs from "video.js";
import "video.js/dist/video-js.css";

export interface MediaUploadProps {
  label: string;
  disabled?: boolean;
  component?: 'img' | 'video';
  path?: string;
  saveLocal?: boolean;
  sourceUrl?: string;
  fileName?: string;
  fileUrl?: string;
  downloadUrl?: string;
  onUploaded: (url: string) => void;
  onVideoDurationChange?: (duration: number) => void;
}

/**
 * This component will support many user cases in this app.
 */
export const MediaUpload = ({
  label,
  disabled,
  component = 'img',
  path, // server local path or cdn path
  saveLocal = false, // to indicate if the file should be saved to server locally or uploaded to CDN
  sourceUrl, // file remote url instead of a local file
  fileName,  // the uploaded file name
  fileUrl, // full url to the uploaded file
  downloadUrl,
  /** the callback will output only file name of having path param, otherwise it will output the full url */
  onUploaded,
  onVideoDurationChange = () => { },
}: MediaUploadProps) => {
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  // use file input in case no remote source url
  const [showFileInput, setShowFileInput] = useState(true);

  useEffect(() => {
    setShowFileInput(!sourceUrl);
  }, [sourceUrl]);

  const inputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const previewUrl = useMemo(() => {
    if (fileUrl) {
      return fileUrl;
    }
    if (fileName) {
      return [process.env.REACT_APP_CDN_DOMAIN, path, fileName].join('/');
    }
    if (sourceUrl) {
      return sourceUrl;
    }
    return '';
  }, [sourceUrl, fileName, fileUrl, path]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    const handleMetadataLoaded = () => {
      onVideoDurationChange(videoRef.current?.duration || 0);
    }
    if (videoRef.current && videoRef.current.tagName === 'VIDEO') {
      videojs(videoRef.current);
      videoRef.current.addEventListener('loadedmetadata', handleMetadataLoaded);
    }
    return videoRef.current.removeEventListener('loadedmetadata', handleMetadataLoaded);
  }, [videoRef.current]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files ? e.target.files[0] : undefined);
  }
  const onUploadLocalFile = () => {
    if (!file) {
      return;
    }
    setUploading(true);
    const form = new FormData();
    form.append('file', file, file.name);
    if (saveLocal) {
      form.append('saveLocal', '1');
    }
    if (path) {
      form.append('path', path);
    }
    axios.post(`${SERVER_URL}/file`, form, {
      headers: getAuthHeader()
    }).then((res) => {
      setFile(undefined);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setUploaded(true);
      /**
       * With path, the output should only the file name and consumer will generate full URL itself
       * Without path, the output should be the full url.
       */
      onUploaded(path ? getFileNameFromUrl(res.data.url) : res.data.url);
      setUploading(false);
    }).catch((e) => {
      toast.error(e.message || 'Something went wrong');
      setUploading(false);
    });
  }

  const uploadRemoteFile = () => {
    setUploading(true);

    axios.post(`${SERVER_URL}/file`, {
      path,
      sourceUrl,
      saveLocal
    }, {
      headers: getAuthHeader()
    }).then((res) => {
      onUploaded(path ? getFileNameFromUrl(res.data.url) : res.data.url);
      setUploaded(true);
      setUploading(false);
    }).catch((e) => {
      toast.error(e.message || 'Something went wrong');
      setUploading(false);
    });
  }


  return (
    <Card variant="outlined">
      {component === 'img' &&
        <>
          {!!previewUrl &&
            <CardMedia
              component={'img'}
              src={previewUrl}
            />
          }
          {!!sourceUrl && !uploaded &&
            <Alert color="warning">This is the Preview Image from remote file. Click Upload to save it to server.</Alert>
          }
        </>
      }
      {component === 'video' &&
        <>
          {!!previewUrl &&
            <>
              <Box className="video-wrapper">
                <CardMedia
                  component={'video'}
                  className="video-js"
                  controls
                  preload="true"
                  ref={videoRef}
                >
                  <source src={previewUrl} type={getVideoType(previewUrl)}/>
                </CardMedia>
              </Box>
              {!!sourceUrl && !uploaded &&
                <Alert color="warning">This is the Preview Video from remote URLClick Upload to save it to server.</Alert>
              }
            </>
          }
        </>
      }
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {label}
          {component === 'img' &&
            <Tooltip title="Please select an image with aspect ratio 16:9 and compress it before uploading. The image size should be less than 2MB.">
              <IconButton size="small">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          }
        </Typography>
        {!showFileInput &&
        <Typography>Upload a local file instead?
          <Button className="ml-1" variant="text" onClick={() => setShowFileInput(true)}>Click here</Button>
        </Typography>
        }
      </CardContent>
      <CardActions>
        {showFileInput &&
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <input ref={inputRef} type="file" accept={getAcceptAttribue(component)} onChange={onChange} disabled={disabled} />
            {!!file &&
              <LoadingButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={onUploadLocalFile}
                disabled={disabled}
                loading={uploading}
              >
                Upload
              </LoadingButton>
            }
          </Box>
        }
        {!!sourceUrl && !showFileInput &&
          <>
            {!!sourceUrl &&
              <Button variant="text">
                <a href={sourceUrl} target="_blank" rel="noopener noreferrer">View remote file</a>
              </Button>
            }
            <LoadingButton
              variant="outlined"
              color="primary"
              size="small"
              onClick={uploadRemoteFile}
              disabled={disabled}
              loading={uploading}
            >
              Upload
            </LoadingButton>
          </>
        }
        {!!downloadUrl &&
          <Button variant="outlined" size="small" href={downloadUrl} target="_blank" download>Download</Button>
        }
        {uploaded &&
          <DoneIcon color="success" />
        }
      </CardActions>
    </Card>
  );
};

const getAcceptAttribue = (component: string) => {
  switch (component) {
    case "img":
      return "image/*"
    case "video":
      return "video/*"
    default:
      return "*"
  }
}

const getVideoType = (url: string) => {
  if (typeof url !== "string") return "";
  if (url.includes("playlist.m3u8")) {
    return "application/x-mpegURL";
  }

  if (url.includes("https://vimeo.com")) {
    return "video/vimeo";
  }

  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return "video/youtube";
  }

  if (url.endsWith(".mp4")) {
    return "video/mp4";
  }
  return "";
};