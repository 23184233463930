import React from "react";
import {
  Box, CircularProgress,
  Grid, Paper
} from "@mui/material";
import ListSection from "components/ListSection";
import { MediaUpload } from "components/MediaUpload";
import { DetailHeading, DynamicFormInputs, useDetailPage } from "pages/Common";
import { useMemo } from "react";

export const PlayerDetail = () => {
  const {
    id,
    data,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    defaultData,
    apiEndpoint: 'player',
    refreshAfterSaving: true
  });

  const mainInputs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        field: 'firstName',
        label: 'First Name',
        type: 'text',
        value: data.firstName
      },
      {
        field: 'lastName',
        label: 'Last Name',
        type: 'text',
        value: data.lastName
      },
      {
        field: 'firstNameKo',
        label: 'Korean First Name',
        type: 'text',
        value: data.firstNameKo
      },
      {
        field: 'lastNameKo',
        label: 'Korean Last Name',
        type: 'text',
        value: data.lastNameKo
      },
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: data.sportId
      },
      {
        field: 'birthday',
        label: 'BirthDay',
        type: 'date',
        value: data.birthday
      },
      {
        field: 'gender',
        label: 'Gender',
        type: 'select',
        value: data.gender,
        options: [{id: 'Male', label: 'Male'}, {id: 'Female', label: 'Female'}]
      },
      {
        field: 'birthplaceId',
        label: 'Birth Place',
        type: 'country',
        value: data.birthplaceId
      },
      {
        field: 'deadStatut',
        label: 'Dead Year',
        type: 'number',
        value: data.deadStatut
      },
      {
        field: 'shortBio',
        label: 'Short Bio',
        type: 'multiline',
        value: data.shortBio
      },
      {
        field: 'userId',
        label: 'Linked User',
        type: 'user',
        value: data.userId
      },
      {
        field: 'avatarCredit',
        label: 'Profile Picture Credit',
        type: 'text',
        value: data.avatarCredit
      },
      {
        field: 'isChampion',
        label: 'Is World Ind Champion',
        type: 'switch',
        value: data.isChampion,
      },
      {
        field: 'isExpertInstruction',
        label: 'Is Expert Instruction',
        type: 'switch',
        value: data.isExpertInstruction,
      },
    ]
  }, [data]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!data &&
          <>
            <DetailHeading
              listLink="/player"
              listName="Players"
              title={id === 'new' ? "New Player" : `${data.firstName} ${data.lastName}`}
              saving={saving}
              onSave={onSave}
              />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={mainInputs} onChange={(value) => onUpdateData(value)} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MediaUpload
                  component="img"
                  label="Profile Image"
                  path={`uploads/players`}
                  fileName={data.playerAvatar}
                  onUploaded={(playerAvatar) => onUpdateData({ playerAvatar })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={4}>
                <Paper>
                  <Box px={2} py={1}>
                    <ListSection
                      apiPath="equipment"
                      getItemRouterLink={(equipment) => `/equipment/${equipment.id}`}
                      routePrefix="equipment"
                      sectionTitle="Equipments"
                      noItemsMessage="No equipments"
                      modalTitle="Add an equipment"
                      items={data.equipments}
                      onUpdate={(equipments) => onUpdateData({equipments})}
                      getPrimaryLabel={(equipment) => equipment.label}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper>
                  <Box px={2} py={1}>
                    <ListSection
                      apiPath="sponsor"
                      getItemRouterLink={(sponsor) => `/sponsor/${sponsor.id}`}
                      routePrefix="sponsor"
                      sectionTitle="Sponsors"
                      noItemsMessage="No sponsors"
                      modalTitle="Add a sponsor"
                      items={data.sponsors}
                      onUpdate={(sponsors) => onUpdateData({sponsors})}
                      getPrimaryLabel={(sponsor) => sponsor.label}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper>
                  <Box px={2} py={1}>
                    <ListSection
                      sectionTitle="Websites"
                      modalTitle="Add a website"
                      noItemsMessage="No websites"
                      modalEditTitle="Edit website"
                      items={data.websites}
                      onUpdate={(websites) => onUpdateData({websites})}
                      getPrimaryLabel={(website) => website.label}
                      getItemRouterLink={(website) => website.path}
                      dynamicInputs={[
                        {
                          field: 'label',
                          label: 'Label',
                          type: 'text',
                        },
                        {
                          field: 'path',
                          label: 'URL',
                          type: 'text',
                        },
                      ]}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </>
        }
      </Box>
    </Paper>
  )
};


const defaultData = {
  gender: 'Male',
  firstName: '',
  lastName: '',
  isChampion: false,
  isPlayer: true,
  isPublic: true,
  isExpertInstruction: false,
  hits: 0,
  sponsors: [],
  equipments: [],
  websites: []
};