import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
  PersonOff as BannedIcon,
  Person as UnbannedIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow, editDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const UserList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const banUser = useCallback((id) => {
    dispatch(editDataGridRow('user', id, {bannedAt: new Date()}, {}, pageKeys.userList));
  }, [dispatch]);

  const unbanUser = useCallback((id) => {
    dispatch(editDataGridRow('user', id, {bannedAt: null}, {}, pageKeys.userList));
  }, [dispatch])

  const deleteUser = useCallback((id, email) => {
    const confirmed = confirm(`Are you sure you want to delete permanently the user with email ${email}? All data related to this user will be deleted!!!`);
    if (confirmed) {
      dispatch(deleteDataGridRow('user', id, {}, {}, pageKeys.userList));
    }

  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 180,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        width: 180,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 250,
      },
      {
        field: 'lastLoginAt',
        headerName: 'Last Login',
        width: 150,
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD HH:mm'),
      },
      {
        field: 'bannedAt',
        headerName: 'Banned At',
        width: 100,
        valueFormatter: ({ value }) => value && moment(value).format('Y-MM-DD'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/user/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => deleteUser(data.id, data.email)}><DeleteForeverIcon />Delete</MenuItem>
              {data.bannedAt &&
                <MenuItem onClick={() => unbanUser(data.id)}><UnbannedIcon />Unban User</MenuItem>
              }
              {!data.bannedAt &&
                <MenuItem onClick={() => banUser(data.id)}><BannedIcon />Ban User</MenuItem>
              }
            </DataGridActionMenu>
          </>
        ),
      },
    ]
  }, [history, banUser, unbanUser, deleteUser]);
  const filters = [
    {
      field: 'isBanned',
      label: 'Is Banned',
      type: 'switch',
      value: 0,
      dataType: 'int'
    },
  ];
  const gridOptions = {
    rowClassRules: {
      "highlight-row": "data.bannedAt"
    }
  }

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="user"
    heading="Users"
    pageKey={pageKeys.userList}
    gridOptions={gridOptions}
  />
}

