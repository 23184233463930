import { ArrowBackIos as ArrowBackIcon, Menu as MenuIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import React, { Component } from "react";

import { logout } from "auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeLanguage } from "store/actions";
import HeaderStyles from "styles/Components/Header";
import { findItemById, getFlagUrlByIso2 } from "utils";

class Header extends Component {
  constructor(props) {
    super(props);
    this.userSignOut = this.userSignOut.bind(this);
  }

  state = {
    anchorEl: null,
    anchorLanguage: null,
    searchExpanded: false,
  };

  handleSettingdToggle = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseSettingMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseLanguageMenu = () => {
    this.setState({ anchorLanguage: null });
  };

  handleSearchExpandToggle = () => {
    this.setState({ searchExpanded: !this.state.searchExpanded });
  };

  handleDrawerToggle = () => {
    this.props.toggleDrawer();
    if (this.state.searchExpanded) this.handleSearchExpandToggle();
  };

  handleNotificationToggle = () => {
    this.props.toogleNotifications();
    if (this.state.searchExpanded) this.handleSearchExpandToggle();
  };

  userSignOut = () => {
    const {userinfo} = this.props.auth;
    this.props.handleChangeAuthInfo(userinfo);
  };

  renderUserInfoPanel = () => {
    const { anchorEl } = this.state;
    const { userInfo } = this.props;
    if (!userInfo) return null;
    return (
      <React.Fragment>
        <Button style={{ color: "white" }} onClick={this.handleSettingdToggle}>
          {userInfo.email}
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseSettingMenu}
        >
          <MenuItem
            onClick={logout}
          >
            Sign out
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  render() {
    const { classes, logo, logoMobile, logoAltText, lang, burgerOpened } = this.props;
    const { anchorLanguage } = this.state;
    const currentLanguage = findItemById(
      lang.languages,
      lang.currentLanguage,
      "iso"
    );
    return (
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ background: "#302d2b" }}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleDrawerToggle}
            size="large">
            {!burgerOpened ? <MenuIcon /> : <ArrowBackIcon />}
          </IconButton>

          <div className={classes.branding}>
            <img
              src={logo}
              alt={logoAltText}
              className={classes.logo}
            />
          </div>
          <Button
            onClick={(event) =>
              this.setState({ anchorLanguage: event.currentTarget })
            }
          >
            <img
              alt={lang.currentLanguage}
              src={getFlagUrlByIso2(
                currentLanguage ? currentLanguage.isoFlag : "gb",
              )}
              width={16}
            />
            <span style={{ marginLeft: "0.5rem", color: "white" }}>
              {lang.currentLanguage ? lang.currentLanguage.toUpperCase() : ""}
            </span>
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorLanguage}
            open={Boolean(anchorLanguage)}
            onClose={this.handleCloseLanguageMenu}
          >
            {lang &&
              Array.isArray(lang.languages) &&
              lang.languages.map((item, index) => {
                return (
                  <MenuItem
                    selected={lang.currentLanguage === item.iso}
                    key={index}
                    onClick={() => {
                      this.handleCloseLanguageMenu();
                      this.props.changeLanguage(item.iso);
                    }}
                  >
                    <img
                      alt={item.label}
                      src={getFlagUrlByIso2(item.isoFlag)}
                      width={16}
                    />
                    <ListItemText
                      primary={item.label}
                      style={{ paddingLeft: "1rem" }}
                    />
                  </MenuItem>
                );
              })}
          </Menu>

          {this.renderUserInfoPanel()}
        </Toolbar>
      </AppBar>
    );
  }
}

Header.prototypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string,
  logoMobile: PropTypes.string,
  logoAltText: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeLanguage,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HeaderStyles)(Header));
