import { drawerWidth } from "../../variables";

const SidebarStyles = (theme) => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: "100%",
    zIndex: theme.zIndex.drawer + 99,
    background: "#302d2b",
  },
});

export default SidebarStyles;
