import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import SidebarStyles from "styles/Components/Sidebar";
import SidebarItem from "./SidebarItem";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

class Sidebar extends Component {
  state = {
    activeRoute: undefined,
  };

  toggleMenu = (index) => {
    if (this.state.activeRoute === index) index = undefined;
    this.setState({ activeRoute: index });
  };

  render() {
    const { classes, opened, toggleDrawer, routes } = this.props;
    const { activeRoute } = this.state;
    const menu = (
      <List component="div">
        {routes.map((route, index) => {
          if (route.invisibleOnSidebar === true) return null;
          return (
            <SidebarItem
              key={index}
              index={index}
              route={route}
              activeRoute={activeRoute}
              toggleMenu={this.toggleMenu}
            />
          );
        })}
      </List>
    );
    return (
      <Fragment>
        <Drawer
          sx={{
            display: { sm: 'none', xs: "none", md: 'block' },
          }}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={opened}
          onClose={toggleDrawer}
        >
          {menu}
        </Drawer>

        <SwipeableDrawer
          sx={{ display: { md: "none", sm: "block" } }}

          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          onOpen={() => {}}
          open={opened}
          onClose={toggleDrawer}
          disableBackdropTransition={!iOS}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {menu}
        </SwipeableDrawer>
      </Fragment>
    );
  }
}

Sidebar.prototypes = {
  classes: PropTypes.object.isRequired,
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object,
};

const SidebarWithRouter = withRouter(Sidebar);

export default withStyles(SidebarStyles)(withWidth()(SidebarWithRouter));
