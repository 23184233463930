import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button, CircularProgress, Grid, Paper,
  Typography
} from "@mui/material";
import { MediaUpload } from "components";
import { TranslationEditor } from "components/TranslationEditor";
import moment from "moment-timezone";
import { DetailHeading, DynamicFormInputs, useDetailPage } from "pages/Common";
import { DynamicInput } from "pages/Common/model";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store";
import { getEventName } from "utils/event";

export const EventDetail = () => {
  const {
    id,
    data: event,
    loading,
    saving,
    onSave,
    onUpdateData,
  } = useDetailPage({
    apiEndpoint: 'event',
    defaultData: defaultEvent,
    autoI18nFromEnglish: true,
    excludedFields: ['authorPlayer']
  });

  const gamesById = useAppSelector((state) => state.games.gamesById);

  const updateMainInputs = (value: Record<string, any>) => {
    if (!value['subscriptionPrice']) {
      value['subscriptionPrice'] = null;
    }
    onUpdateData(value);
  }

  const mainInputs: DynamicInput[] = useMemo(() => {
    return [
      {
        field: 'isLive',
        label: 'Is Live',
        type: 'switch',
        value: event?.isLive
      },
      {
        field: 'isMasterclass',
        label: 'Master Class',
        type: 'switch',
        value: event?.isMasterclass
      },
      {
        field: 'timezone',
        label: 'Time Zone',
        type: 'autocomplete',
        value: event?.timezone,
        options: moment.tz.names()
      },
      {
        field: 'gameId',
        label: 'Game',
        type: 'game',
        value: event?.gameId
      },
      {
        field: 'startTime',
        label: 'Start Time',
        type: 'datetime',
        timezone: event?.timezone,
        value: event?.startTime
      },
      {
        field: 'endTime',
        label: 'End Time',
        type: 'datetime',
        timezone: event?.timezone,
        value: event?.endTime
      },
      {
        field: 'eventTypeId',
        label: 'Event Type',
        type: 'eventType',
        value: event?.eventTypeId
      },
      {
        field: 'parentEventId',
        label: 'Parent Event',
        type: 'event',
        value: event?.parentEventId
      },
      {
        field: 'subscriptionPrice',
        label: 'Subscription Price (€)',
        type: 'text',
        value: event?.subscriptionPrice
      },
      {
        field: 'subscriptionPlanId',
        label: 'Pabbly Plan Id',
        type: 'text',
        disabled: true,
        value: event?.subscriptionPlanId
      },
      {
        field: 'nextStreamingTime',
        label: 'Next Streaming Time',
        type: 'datetime',
        timezone: event?.timezone,
        value: event?.nextStreamingTime
      },
      {
        field: 'externalUrl',
        label: 'External URL',
        type: 'text',
        value: event?.externalUrl,
        description: 'External URL of the event. Ex: YouTube'
      },
    ]
  }, [event]);

  const locationInputs: DynamicInput[] = useMemo(() => {
    if (!event) {
      return [];
    }
    return [
      {
        field: 'address',
        label: 'Address',
        type: 'text',
        value: event.address
      },
      {
        field: 'zipCode',
        label: 'Zip Code',
        type: 'text',
        value: event.zipCode
      },
      {
        field: 'city',
        label: 'City',
        type: 'text',
        value: event.city
      },
      {
        field: 'countryId',
        label: 'Country',
        type: 'country',
        value: event.countryId
      },

    ]
  }, [event]);

  const paramInputs: DynamicInput[] = useMemo(() => {
    return [
      {
        field: 'currency',
        label: 'Prize Currency',
        type: 'select',
        options: [
          { id: null, label: "Select a currency"},
          { id: "USD", label: "US Dollar" },
          { id: "EUR", label: "Euro" },
          { id: "GBP", label: "Pound Sterling" }
        ],
        value: event?.currency
      },
      {
        field: 'nbSets',
        label: 'Number of sets',
        type: 'number',
        value: event?.nbSets
      },
      {
        field: 'level',
        label: 'Level',
        type: 'select',
        options: [{ id: "free", label: "Free" }, { id: "premium", label: "Premium" }, { id: "club", label: "Club" }],
        value: event?.level || "free"
      },
      {
        field: 'isHighlighted',
        label: 'Is Highlighted',
        type: 'switch',
        value: event?.isHighlighted
      },
      {
        field: 'isTeam',
        label: 'Is Team',
        type: 'switch',
        value: event?.isTeam
      },
      {
        field: 'isRankingOnline',
        label: 'Is Ranking Online',
        type: 'switch',
        value: event?.isRankingOnline
      },
    ]
  }, [event]);

  const backgroundSettingInputs: DynamicInput[] = useMemo(() => {
    let bgConfig: Record<string, any> = {};
    try {
      bgConfig = event?.config ? JSON.parse(event.config).background : {}
      
    } catch (error) {
      console.warn(error);
    }
    return [
      {
        field: 'backgroundColor',
        label: 'Background Color',
        type: 'color',
        value: bgConfig.backgroundColor  || '#02023c'
      },
      {
        field: 'backgroundImage',
        label: 'Background Image',
        type: 'file',
        value: bgConfig.backgroundImage || ''
      },
      {
        field: 'backgroundPosition',
        label: 'Background Position',
        type: 'select',
        options: [
          {id: 'center', label: 'Center'},
          {id: 'top left', label: 'Top Left'},
          {id: 'top right', label: 'Top Right'},
          {id: 'bottom left', label: 'Bottom Left'},
          {id: 'bottom right', label: 'Bottom Right'},
        ],
        value: bgConfig.backgroundPosition || 'center'
      },
      {
        field: 'backgroundSize',
        label: 'Background Size',
        type: 'select',
        options: [
          {id: 'cover', label: 'Cover'},
          {id: 'contain', label: 'Contain'},
          {id: 'auto', label: 'Auto'},
        ],
        value: bgConfig.backgroundSize || 'cover'
      },
      {
        field: 'backgroundRepeat',
        label: 'Background Repeat',
        type: 'select',
        options: [
          {id: 'repeat', label: 'Repeat'},
          {id: 'no-repeat', label: 'No Repeat'},
          {id: 'no-repeat', label: 'No Repeat'},
          {id: 'repeat-x', label: 'Repeat Horizontally'},
          {id: 'repeat-y', label: 'Repeat Vertically'},
        ],
        value: bgConfig.backgroundRepeat || 'repeat'
      },
    ]
  }, [event]);

  return (
    <Paper>
      <Box p={2}>
        {loading &&
          <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!!event &&
          <>
            <DetailHeading
              listLink="/event"
              listName="Events"
              title={id === 'new' ? "New Event" : getEventName(event, gamesById)}
              saving={saving}
              onSave={onSave}
              />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={mainInputs} onChange={updateMainInputs} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                {event.id &&
                  <MediaUpload
                    component="img"
                    label="Thumbnail"
                    path={`uploads/events/${event.id}`}
                    fileName={event.image}
                    onUploaded={(image) => onUpdateData({ image })}
                    disabled={!event.id}
                  />
                }
              </Grid>
            </Grid>
            <Grid container mt={1} spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={locationInputs} onChange={(value) => onUpdateData(value)} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paper sx={{ padding: 2 }}>
                  <DynamicFormInputs inputs={paramInputs} onChange={(value) => onUpdateData(value)} />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Accordion>
                  <AccordionSummary id="event-background" aria-controls="panel-content">
                    Background Setting
                  </AccordionSummary>
                  <AccordionDetails>
                    <DynamicFormInputs inputs={backgroundSettingInputs} onChange={(background) => onUpdateData({config: JSON.stringify({background})})} />
                    <Box textAlign="right" mt={4}>
                      <Button variant="outlined" color="error" onClick={() => onUpdateData({config: null})}>Clear</Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Paper sx={{ padding: 2, marginTop: 2 }}>
              <TranslationEditor
                i18n={event.i18n}
                inputs={[
                  {field: 'additionalInfo', type: 'multiline', label: 'Additional Info', value: ''},
                  {field: 'liveDescription', type: 'richtext', label: 'Live Description', value: ''},
                ]}
                onUpdate={(i18n: any) => onUpdateData({i18n})}
              />
            </Paper>
          </>
        }
      </Box>
    </Paper>
  )
};

const defaultEvent = {
  parentEventId: null,
  gameId: "",
  currency: "USD",
  eventTypeId: null,
  startTime: null,
  endTime: null,
  address: "",
  zipCode: "",
  city: "",
  countryId: null,
  image: "",
  nbSets: 0,
  isLive: true,
  hits: 0,
  isHighlighted: false,
  isTeam: false,
  level: "club",
  timezone: null,
  nextStreamingTime: null,
  isMasterClass: false,
  isStreaming: false,
  i18n:[],
};
