import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";
import { bindActionCreators } from "redux";
import { HeaderDashboard, Sidebar, Workspace } from "../components";
import {
  getGames,
  getCountries,
  getLanguagues,
  getSports,
  getCurrentUser
} from "store/actions";
import routes from "../routes.admin";
import DashboardStyles from "../styles/Pages/Dashboard";
import { MobileBreakpoint } from "../styles/variables";
import { CircularProgress } from "@mui/material";

function resizeDispatch() {
  if (typeof Event === "function") {
    window.dispatchEvent(new Event("resize"));
  } else {
    const evt = window.document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: true,
      notificationsOpen: false,
      type: "light",
      direction: "ltr",
      openSpeedDial: false,
    };

    this.props.getLanguagues();
    this.props.getGames();
    this.props.getCountries();
    this.props.getSports();
    const { loading, userInfo } = this.props.auth;
    if (!userInfo && !loading) {
      this.props.getCurrentUser();
    }
  }

  mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

  handleDrawerToggle = () => {
    this.setState({ opened: !this.state.opened });
    resizeDispatch();
  };

  handleNotificationToggle = () => {
    this.setState({ notificationsOpen: !this.state.notificationsOpen });
  };

  handleFullscreenToggle = () => {
    const element = document.querySelector("#root");
    const isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  handleSpeedDialOpen = () => {
    this.setState({ openSpeedDial: true });
  };

  handleSpeedDialClose = () => {
    this.setState({ openSpeedDial: false });
  };

  setInitialSidebarOpen = (props) => {
    const { location } = props;
    let sidebarOpened = true;
    routes.items.forEach((item) => {
      if (item.type === "submenu") {
        item.children.forEach((subItem) => {
          const isCurrentPath = matchPath(
            location.pathname,
            item.path + subItem.path
          );
          if (
            isCurrentPath &&
            isCurrentPath.isExact &&
            subItem.hideSidebar === true
          ) {
            sidebarOpened = false;
          }
        });
      }
    });
    this.setState({ opened: sidebarOpened });
  };

  UNSAFE_componentWillReceiveProps = (props) => {
    if (JSON.stringify(props.location) !== JSON.stringify(this.props.location))
      this.setInitialSidebarOpen(props);
  };

  componentDidMount() {
    this.setInitialSidebarOpen(this.props);
    if (this.mediaMatcher.matches) this.setState({ opened: false });

    this.mediaMatcher.addListener((match) => {
      setTimeout(() => {
        if (match.matches) {
          this.setState({ opened: false });
        } else {
          this.setState({ opened: true });
        }
      }, 300);
    });

    this.unlisten = this.props.history.listen(() => {
      if (this.mediaMatcher.matches) this.setState({ opened: false });
      let dom = document.querySelector("#root > div > main");
      if (dom) dom.scrollTop = 0;
    });
  }

  componentWillUnmount() {
    this.unlisten();
    this.mediaMatcher.removeListener((match) => {
      setTimeout(() => {
        if (match.matches) {
          this.setState({ opened: false });
        } else {
          this.setState({ opened: true });
        }
      }, 300);
    });
  }

  render() {
    const { classes, auth } = this.props;
    const { opened } = this.state;

    if (auth.loading) {
      return (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    let sidebarOpened = opened;
    const getRoutes = (
      <Switch>
        {routes.items.map((item, index) =>
          item.type === "external" ? (
            <Route
              exact
              path={item.path}
              component={item.component}
              name={item.name}
              key={index}
            />
          ) : item.type === "submenu" ? (
            item.children.map((subItem) => {
              return (
                <Route
                  exact
                  key={subItem.name}
                  path={`${item.path}${subItem.path}`}
                  component={subItem.component}
                  name={subItem.name}
                />
              );
            })
          ) : (
            <Route
              exact
              path={item.path}
              component={item.component}
              name={item.name}
              key={index}
            />
          )
        )}
        <Redirect to="/404" />
      </Switch>
    );

    if (!auth.userInfo && !auth.loading && auth.error) {
      return <Redirect to={`/login`} />;
    }
    if (auth.userInfo && !auth.userInfo.isAdmin) {
      return <Redirect to={`/login`} />;
    }
    if (!auth.userInfo) {
      return null;
    }

    return (
      <Fragment>
        <HeaderDashboard
          burgerOpened={sidebarOpened}
          logoAltText="E-Commerce"
          logo="https://kozoom.b-cdn.net/assets/static/images/logos/kozoom--light.png"
          toggleDrawer={this.handleDrawerToggle}
          toogleNotifications={this.handleNotificationToggle}
          toggleFullscreen={this.handleFullscreenToggle}
          userInfo={auth.userInfo}
        />
        <div className={classNames(classes.panel, "theme-dark")}>
          <Sidebar
            routes={routes.items}
            opened={sidebarOpened}
            toggleDrawer={this.handleDrawerToggle}
          />
          <Workspace opened={sidebarOpened}>{getRoutes}</Workspace>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGames,
      getCurrentUser,
      getCountries,
      getSports,
      getLanguagues,
    },
    dispatch
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(DashboardStyles)(Dashboard));
