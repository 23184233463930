import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { UPLOADED_MEDIA_URL } from 'constants/serverUrl';
import { DataGridActionMenu, DataGridListPage } from 'pages/Common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDataGridRow } from 'store/actions';
import { pageKeys } from 'store/reducers/dataGrid.reducer';


export const PlayerList = () => {
  const countriesById = useSelector(state => state.countries.countriesById);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDeletePlayer = useCallback((playerId) => {
    const confirmed = window.confirm("Are you sure you want to delete this player permanently?");
    if (confirmed) {
      dispatch(deleteDataGridRow(`player`, playerId, {}, {}, pageKeys.playerList));
    }
  }, [dispatch]);
  
  const columns = useMemo(() => {

    return [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 150,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        width: 150,
      },
      {
        field: 'playerAvatar',
        headerName: 'Avatar',
        width: 150,
        cellRenderer: ({ data }) => (
          <img src={`${UPLOADED_MEDIA_URL}/uploads/players/${data.playerAvatar}`} alt="Avatar" width="50"/>
        ),
      },
      {
        field: 'countryId',
        headerName: 'Country',
        flex: 1,
        valueFormatter: ({ value }) => countriesById[value] ? countriesById[value].name : null,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        cellRenderer: ({ data }) => (
          <>
            <DataGridActionMenu>
              <MenuItem onClick={() => history.push(`/player/${data.id}`)}><EditIcon />Edit</MenuItem>
              <MenuItem onClick={() => onDeletePlayer(data.id)}><DeleteForeverIcon/>Remove</MenuItem>
            </DataGridActionMenu>
          </>
        ),
        cellStyle: { textAlign: 'center' },
        headerClass: 'text-center'
      },
    ]
  }, [countriesById, history, onDeletePlayer]);

  const filters = useMemo(() => [
      {
        field: 'sportId',
        label: 'Sport',
        type: 'sport',
        value: null
      },
      {
        field: 'hasMasterclass',
        label: 'Has Masterclass',
        type: 'switch',
        dataType: 'int'
      },
    ], []);

  return <DataGridListPage
    columns={columns}
    filters={filters}
    apiEndpoint="player"
    heading="Players"
    pageKey={pageKeys.playerList}
    onAdd={() => history.push(`/player/new`)}
  />
}

