import moment from "moment-timezone";

export function formatEventDate(dateTime) {
  if (!dateTime) {
    return '';
  }
  if (dateTime.date) {
    return moment(dateTime.date).format("MMM DD, Y");
  }
  return moment(dateTime).format("MMM DD, Y");
}

export function secondsToHours(seconds) {
  return Math.round((seconds / 3600));
}

export const formatSeconds = (second, showMillisecond = false) => {
  let str = "00";
  let milliSecond = second - Math.floor(second);

  milliSecond = milliSecond.toFixed(3).toString().substr(2);

  let hours = parseInt(second / 3600, 10);
  let minutes = parseInt((second - hours * 3600) / 60, 10);
  let seconds = parseInt(second % 60, 10);
  str = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;

  if (showMillisecond) str += "." + milliSecond;

  return str;
};

// parse format H:m:s to seconds
export const hmsToSecondsOnly = (str) => {
  var p = str.split(':'),
      s = 0, m = 1;

  while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
  }
  return s;
}

/**
 *
 * @param {number} ts timestamp
 * @param {string} lang language
 * @param {string} format default format
 * @param {boolean} enableFromNow check from now
 * @param {number} limitDate set limit dates when check from now
 */
export const formatDate = (
  ts,
  lang = "en",
  format = "l",
  enableFromNow = false,
  limitDate = 12
) => {
  moment.locale(lang);
  if (
    enableFromNow &&
    moment(new Date()).diff(moment(ts), "days") <= limitDate
  ) {
    return moment(ts).fromNow();
  }
  return moment(ts).format(format);
};

export const formatTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(time).format(format);
}

export const formatTimeWithTz = (time, timezone, format = 'Y-MM-DD HH:mm:ss z') => {
  if (timezone) {
    return moment(time).tz(timezone).format(format);
  } else {
    return moment(time).format(format);
  }
}
