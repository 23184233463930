import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import WrapperStyles from "styles/Wrapper";

const Wrapper = (props) => {
  const { classes, children, padding, style } = props;

  return (
    <div className={padding ? classes.root : null} style={{ ...style }}>
      {children}
    </div>
  );
};

Wrapper.prototypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
};

export default withStyles(WrapperStyles)(Wrapper);
