import React, { useEffect, useRef, useState, useMemo } from 'react';
import { TablePagination, Paper, Box, Snackbar, Alert, CircularProgress } from '@mui/material';
import { DataTableToolbar } from './toolbar';
import PropTypes from "prop-types";
import { getFieldValuesObject } from './DynamicFormInputs';
import { useDispatch, useSelector } from 'react-redux';
import { getDataGridList } from 'store/actions';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
// Optional Theme applied to the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './style.css';
import { difference } from 'lodash';


export const DataGridListPage = ({
  columns = [],
  filters = [],
  apiEndpoint,
  searchKey = "searchStr",
  searchPlaceholder = "Search",
  initialQueryParams = {},
  // List of required query params to trigger API request. If there is one query param missing, API request will not be called.
  requiredQueryParams = [],
  heading = '',
  pageKey,
  hasPagination = true,
  rowsPerPage = 20,
  hideSearchInput = false,
  onSaveRankings = () => { },
  onAdd,
  gridOptions,
  children
}) => {
  const gridRef = useRef();
  const [rows, setRows] = useState([]);
  const [showSaveRanking, setshowSaveRanking] = useState(false);
  const [params, setParams] = useState({
    ...initialQueryParams,
    ...(hasPagination ? {
      limit: rowsPerPage,
      offset: 0
    } : null),
    ...getFieldValuesObject(filters)
  });
  const dataGrid = useSelector(state => state.dataGrid[pageKey]);
  const [pagination, setPagination] = useState({
    count: -1,
    rowsPerPage,
    page: 0,
    rowsPerPageOptions: [20, 50, 100, 200]
  });
  const [error, setError] = useState({
    show: false,
    message: ''
  });

  const dispatch = useDispatch();

  const handleCloseErrorMessage = () => {
    setError({
      shown: false,
      message: '',
    });
  }

  useEffect(() => {
    if (!difference(requiredQueryParams, Object.keys(params)).length) {
      dispatch(getDataGridList(apiEndpoint, params, pageKey));
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    setRows(Object.values(dataGrid.dataById).sort((a, b) => {
      return dataGrid.ids.indexOf(a.id) - dataGrid.ids.indexOf(b.id);
    }));
    if (dataGrid.error) {
      setError({
        show: true,
        message: dataGrid.error
      });
    }
  }, [dataGrid]);

  const onPaginationChange = (newValue) => {
    setPagination(newValue);
    const offset = newValue.page * newValue.rowsPerPage;
    const limit = newValue.rowsPerPage;
    setParams({
      ...params,
      offset,
      limit
    });
  }

  const onFiltersChange = (filterData) => {
    setParams({
      ...params,
      ...filterData
    });
  }

  const onRowDragEnd = (e) => {
    setshowSaveRanking(true);
  };

  const saveRankings = () => {
    const ids = [];
    gridRef.current.api.forEachNode(({ data }) => {
      ids.push(data.id);
    });
    onSaveRankings(ids);
    setshowSaveRanking(false);
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
    };
  }, []);

  return (
    <Paper>
      <Box px={2}>
        <DataTableToolbar
          heading={heading}
          searchKey={searchKey}
          searchPlaceholder={searchPlaceholder}
          filters={filters}
          defaultColDef={defaultColDef}
          onFiltersChange={onFiltersChange}
          hideSearchInput={hideSearchInput}
          onAdd={onAdd}
          showSaveRanking={showSaveRanking}
          onSaveRankings={saveRankings}
        />
        {children}
        <div style={{ height: 'calc(100vh - 188px)', width: "100%", position: 'relative' }} className="ag-theme-quartz">
          <AgGridReact
            ref={gridRef}
            rowData={rows}
            columnDefs={columns}
            rowDragManaged={true}
            onRowDragEnd={onRowDragEnd}
            gridOptions={gridOptions}
          >
          </AgGridReact>
          {dataGrid.loading &&
            <Box className="data-grid-spinner">
              <CircularProgress />
            </Box>
          }
        </div>
        {hasPagination &&
          <TablePagination
            {...pagination}
            component="div"
            onPageChange={((e, page) => onPaginationChange({
              ...pagination,
              page
            }))}
            onRowsPerPageChange={(e) => onPaginationChange({
              ...pagination,
              rowsPerPage: e.target.value
            })}
          />
        }
        {!hasPagination &&
          <Box height={56}/>
        }
      </Box>
      <Snackbar open={error.show} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleCloseErrorMessage}>
        <Alert onClose={handleCloseErrorMessage} severity="error" sx={{ width: '100%' }}>
          {error.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

DataGridListPage.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any),
  filters: PropTypes.arrayOf(PropTypes.any),
  apiEndpoint: PropTypes.string,
  searchKey: PropTypes.string,
  initialQueryParams: PropTypes.object,
};

