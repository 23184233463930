import { applyMiddleware, createStore, compose } from "redux";
import {thunk} from "redux-thunk";
import rootReducer from "./store/reducers";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(rootReducer, compose(applyMiddleware(thunk)));
  return { store };
};
export const { store } = configureStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
