const SidebarItemStyles = (theme) => ({
  badge: {
    width: "20px",
    height: "20px",
    display: "flex",
    zIndex: 1,
    flexWrap: "wrap",
    fontSize: "0.75rem",
    alignItems: "center",
    borderRadius: "50%",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  menuLink: {
    position: "relative",
    display: "block",
  },
  menuItem: {
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    paddingLeft: 0,
    paddingRight: 0,
    "& p, & svg": {
      color: "white",
    },
    cursor: "pointer"
  },
  menuIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  menuSubItem: {
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    paddingLeft: "55px",
    paddingRight: "55px",
    "& p": {
      color: "white",
    },
  },
  menuCollapsed: {
    backgroundColor: theme.palette.action.hover,
  },
  menuActive: {
    opacity: 0.9,
  },
  menuClosed: {
    backgroundColor: "transparent",
  },
  caret: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: "auto",
    color: "white",
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
});

export default SidebarItemStyles;
